import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo/seo"
import Header from "../components/header/header"
import Navigation from "../components/navigation/navigation"
import Layout from "../components/layout/layout"
import Lists from "../components/lists/lists"
import Newsletter from "../components/newsletter/newsletter"
import Footer from "../components/footer/footer"
import TextHero from "../components/text-hero/text-hero"
import Wrapper from "../components/layout/wrapper/wrapper"
import Styles from "./404.module.scss"

export const query = graphql`
  query Query404 {
    prismic {
      allNavigations {
        edges {
          node {
            subscription_error_message
            subscription_success_message
            header {
              ... on PRISMIC_NavigationHeaderMenu {
                primary {
                  title
                  type
                  link {
                    _linkType
                    ... on PRISMIC_Page {
                      title
                      _meta {
                        uid
                      }
                      parent {
                        ... on PRISMIC_Page {
                          title
                          _meta {
                            uid
                          }
                        }
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      _linkType
                      url
                    }
                  }
                }
                fields {
                  link {
                    ... on PRISMIC_Page {
                      title
                      _meta {
                        uid
                      }
                      parent {
                        ... on PRISMIC_Page {
                          title
                          _meta {
                            uid
                          }
                          parent {
                            ... on PRISMIC_Page {
                              title
                              _meta {
                                uid
                              }
                            }
                          }
                        }
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      _linkType
                      url
                    }
                  }
                  title
                  overview
                }
              }
            }
            footer {
              ... on PRISMIC_NavigationFooterMenu {
                primary {
                  title
                }
                fields {
                  indented
                  link {
                    ... on PRISMIC_Page {
                      title
                      _meta {
                        uid
                      }
                      parent {
                        ... on PRISMIC_Page {
                          title
                          _meta {
                            uid
                          }
                          parent {
                            ... on PRISMIC_Page {
                              title
                              _meta {
                                uid
                              }
                            }
                          }
                        }
                      }
                    }                   
                    ... on PRISMIC__ExternalLink {
                      _linkType
                      url
                    }
                  }
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`
const Page = ({ data }) => {
  const {
    header,
    footer,
    subscription_success_message,
    subscription_error_message,
  } = data.prismic.allNavigations.edges[0].node

  return (
    <>
      <SEO
        title="404 Not found"
        description={"That page could not be found."}
      />
      <Layout
        header={
          <Header
            showFooterHeader={true}
            navigation={<Navigation items={header} />}
          />
        }
        body={
          <Wrapper>
            <div className={Styles.container}>
              <TextHero title="404 not found" />
              <p>That page could not be found.</p>
              <ul className={Styles.pageList}>
                <li>
                  <Link to="/">Home</Link>
                </li>
              </ul>
            </div>
          </Wrapper>
        }
        footer={
          <Footer
            navigation={<Lists items={footer} />}
            newsletter={
              <Newsletter
                subscriptionSuccessMessage={subscription_success_message}
                subscriptionErrorMessage={subscription_error_message}
              />
            }
          />
        }
      />
    </>
  )
}

export default Page
